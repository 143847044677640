@import "./_var.scss";

body,
html {
  background: #ffffff;
  color: #373737;
  font-family: $fontfamily;

  &.lock {
    overflow: hidden;
  }
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.ovf-hidden {
  overflow: hidden;
}

.preloader {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #d2e6e4;
  opacity: 1;
  transition: all 0.2s;

  &.hidePreloader {
    opacity: 0;
    pointer-events: none;
  }
}

.font-40 {
  font-weight: 800;
  font-size: 40px;
  line-height: 150%;
  color: #2196f3;

  @media (max-width: $md4+px) {
    font-size: 35px;
  }

  @media (max-width: $md6+px) {
    font-size: 25px;
  }
}

.font-35 {
  font-weight: 800;
  font-size: 35px;
  line-height: 150%;
  color: #2196f3;

  @media (max-width: $md4+px) {
    font-size: 30px;
  }

  @media (max-width: $md6+px) {
    font-size: 20px;
  }
}
.font-30 {
  font-weight: 800;
  font-size: 30px;
  line-height: 1;
  color: #2196f3;
  @media (max-width: $md4+px) {
    font-size: 25px;
  }
  @media (max-width: $md6+px) {
    font-size: 14px;
  }
}
.font-23 {
  font-weight: 400;
  font-size: 23px;
  line-height: 34px;

  @media (max-width: $md4+px) {
    font-size: 21px;
    line-height: 32px;
  }

  @media (max-width: $md6+px) {
    font-size: 18px;
    line-height: 27px;
  }
}

.font-20 {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;

  @media (max-width: $md4+px) {
    font-size: 18px;
  }

  @media (max-width: $md6+px) {
    font-size: 14px;
  }
}

.font-18 {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;

  @media (max-width: $md4+px) {
    font-size: 16px;
  }

  @media (max-width: $md6+px) {
    font-size: 14px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
}


.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.col-md4-6 {
  @media (min-width: $md4+px) {
    width: 50%;
  }
}