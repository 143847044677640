@import '../../../styles/var';

.theOnlineBroker {
    position: relative;
    margin: 130px 0;
    @media(max-width: $md4+px) {
        margin: 110px 0;
    }
    @media(max-width: $md6+px) {
        margin: 80px 0;
    }
    .title {
        max-width: 729px;
    }
    .content {
        display: flex;
        justify-content: space-between;
        margin: 30px 0 0 0;
        @media(max-width: $md3+px) {
            flex-direction: column;
            gap: 15px;
            justify-content: unset;
        }
        .col {
            max-width: 719px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            @media(max-width: $md1+px) {
                max-width: 600px;
            }
            @media(max-width: $md2+px) {
                max-width: 560px;
            }
            @media(max-width: $md3+px) {
                max-width: 100%;
            }
        }
        ul {
            padding-left: 20px;
            @media(max-width: $md6+px) {
                gap: 10px!important;
            }
            li {
                list-style-type: disc;
                list-style-position: outside;
            }
        }
    }
    .awards {
        margin-top: 70px;
        display: flex;
        gap: 100px;
        justify-content: center;
        align-items: center;
        @media(max-width: $md1+px) {
            margin-top: 60px;
        }
        @media(max-width: $md4+px) {
            margin-top: 50px;
        }
        @media(max-width: $md6+px) {
            margin-top: 30px;
        }
        @media(max-width: $md4+px) {
            justify-content: space-between;
            gap: 0;
        }
        @media(max-width: 600px) {
            justify-content: space-around;
        }
        @media(max-width: $md6+px) {
            justify-content: space-between;
            align-items: flex-end;
        }
        .award_01, .award_03 {
            height: 200px;
            @media(max-width: $md4+px) {
                height: 150px;
            }
            @media(max-width: 600px) {
                height: 84px;
            }
        }
        .award_02 {
            height: 250px;
            @media(max-width: $md4+px) {
                height: 195px;
            }
            @media(max-width: 600px) {
                height: 105px;
                transform: translateY(-10px);
            }
        }
    }
}