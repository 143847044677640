@import '../../../styles/var';

.underBanner {
    position: relative;
    z-index: 5;
    background: #2196F3;
    .contentWr {
        padding: 50px 0;
        @media(max-width: $md3+px) {
            padding: 50px 0 100px 0;
        }
        @media(max-width: $md6+px) {
            padding: 50px 0;
        }
        .btn {
            position: relative;
            z-index: 6;
            margin-top: 40px;
            @media(max-width: $md3+px) {
                margin-top: 50px;
            }
            @media(max-width: $md6+px) {
                text-align: center;
                margin-top: 40px;
            }
        }
    }
    .content {
        position: relative;
        z-index: 6;
        display: flex;
        justify-content: space-between;
        color: #FFFFFF;
        @media(max-width: $md3+px) {
            justify-content: unset;
            flex-direction: column;
            gap: 15px;
        }
        .col {
            width: 722px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            @media(max-width: $md1+px) {
                width: 610px;
            }
            @media(max-width: $md2+px) {
                width: 550px;
            }
            @media(max-width: $md3+px) {
                gap: 15px;
                width: 100%;
            }
        }
    }
    .image {
        position: absolute;
        z-index: 5;
        bottom: 1px;
        left: calc(50% - 250px);
        width: 302px;
        @media(max-width: $md1+px) {
            width: 250px;
        }
        @media(max-width: $md3+px) {
            width: 270px;
            left: unset;
            right: 100px;
        }
        @media(max-width: $md5+px) {
            right: 20px;
        }
        @media(max-width: $md6+px) {
            position: relative;
            display: flex;
            margin: 10px auto 0 auto;
            right: unset;
            bottom: unset;
            width: 216px;
        }
    }
}