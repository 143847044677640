@import "../../../styles/_var.scss";
.headerNavLink {
    display: block !important;
    font-weight: 500;
    font-size: 16px;
    color: #373737;
    margin-left: 60px;
    @media (max-width: $md1+px) {
        margin-left: 30px;
    }
    @media (max-width: $md3+px) {
        margin: 0 auto 30px auto !important;
        display: inline-block !important;
    }

    &:first-child {
        margin-left: 0;
    }
    &.active {
        position: relative;
        &:after {
            content: "";
            display: inline-block;
            left: 0;
            bottom: -4px;
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #373737;
        }
    }
}
