@import '../../../styles/var';

.banner {
    position: relative;
    margin-top: 229px;
    z-index: 3;
    display: grid;
    grid-template-columns: 800px 1fr;
    @media(max-width: $md2+px) {
        grid-template-columns: 700px 1fr;
    }
    @media(max-width: $md3+px) {
        display: block;
    }
    @media(max-width: $md4+px) {
        margin-top: 190px;
    }
    @media(max-width: $md6+px) {
        margin-top: 130px;
    }
    .content {
        position: relative;
        z-index: 4;
        .title {
            max-width: 653px;
            @media(max-width: $md6+px) {
                font-weight: 800;
                font-size: 25px;
                line-height: 38px;
            }
        }
        .text {
            margin: 30px 0 50px 0;
            font-weight: 500;
            @media(max-width: $md6+px) {
                margin: 20px 0 40px 0;
            }
        }
        .cards {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(1, 1fr);
            gap: 35px;
            @media(max-width: $md4+px) {
                gap: 30px;
            }
            @media(max-width: $md5+px) {
                gap: 12px;
            }
            @media(max-width: $md6+px) {
                gap: 8px;
            }
            .card {
                background: rgba(255, 253, 253, 0.6);
                box-shadow: 0px 5px 10px rgba(51, 48, 48, 0.25);
                backdrop-filter: blur(5px);
                border-radius: 16px;
                text-align: center;
                padding: 20px 5px 10px 5px;
                @media(max-width: 600px) {
                    padding: 7px 0 5px 0;
                    border-radius: 8.05378px;
                }
                &Title {
                    font-weight: 700;
                    font-size: 50px;
                    line-height: 40px;
                    color: #2196F3;
                    @media(max-width: $md4+px) {
                        font-size: 42px;
                    }
                    @media(max-width: 600px) {
                        font-size: 18px;
                        line-height: 20px;
                    }
                }
                &Text {
                    @media(max-width: 600px) {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }
        }
        // .btn {
        //     margin-top: 60px;
        //     @media(max-width: $md1+px) {
        //         margin-top: 50px;
        //     }
        //     @media(max-width: $md3+px) {
        //         text-align: center;
        //     }
        // }
    }
    .image {
        position: relative;
        z-index: 4;
        img {
            display: flex;
            margin: 0 auto;
            width: 408px;
            @media(max-width: $md1+px) {
                width: 380px;
                margin-right: 0;
            }
            @media(max-width: $md3+px) {
                margin: 0 auto;
                margin-top: 60px;
            }
            @media(max-width: $md6+px) {
                width: 230px;
                margin-top: 30px;
            }
        }
    }
    .rect_01 {
        @media(max-width: $md6+px) {
            position: absolute;
            z-index: 3;
            width: 202px;
            height: 202px;
            background: rgba(255, 253, 253, 0.6);
            box-shadow: 0px 2.28485px 4.5697px rgba(51, 48, 48, 0.25);
            backdrop-filter: blur(2.40244px);
            border-radius: 7.68781px;
            transform: rotate(45deg);
            top: -100px;
            left: -20px;
        }
    }
}