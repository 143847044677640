@import "../../../styles/_var.scss";
.low {
    margin-bottom: 130px;
    @media (max-width: $md4+px) {
        margin-bottom: 110px;
    }
    @media (max-width: $md2+px) {
        margin-bottom: 110px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 80px;
    }
}
.lowImg1 {
    display: inline-block;
    left: -200px;
    top: 200px;
    transition: all 0.3s linear;
    position: absolute;
    width: 370px;
    height: 496px;
    background: url("../../../assets/img/fees/2/1.png") 0 0 / contain no-repeat;

    @media (max-width: $md3+px) {
        left: -150px;
        top: 0;
    }
    @media (max-width: $md4+px) {
        display: none;
    }
}
.lowBody {
    position: relative;
}
.lowTitle {
    max-width: 500px;
    text-align: center;
    margin: 0 auto 30px auto;
}

.lowSubtitle {
    font-weight: 500;
    margin-bottom: 60px;
    text-align: center;
    @media (max-width: $md5+px) {
        margin-bottom: 30px;
    }
}
.lowTableWrap {
    width: 482px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}
.lowTableWrapRow {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #aaaaaa;
    padding: 13px 0;
}
.lowTableWrapRowColumn {
    flex: 0 1 50%;
    font-size: 16px;
    line-height: 1.1;
    color: #373737;
}
.lowTableWrapScroll {
    overflow: auto;
    padding-bottom: 20px;
    &::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 10px;
        border: 1px solid #56bfef;
    }
    &::-webkit-scrollbar {
        height: 6px;
        background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 25px;
        background-color: #56bfef;
    }
}
.lowImg {
    display: none;
    text-align: center;
    @media (max-width: $md4+px) {
        display: block;
        margin: 30px auto 0 auto;
    }
}
