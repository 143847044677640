@import "../../../styles/_var.scss";
.rates {
    margin-bottom: 130px;
    @media (max-width: $md4+px) {
        margin-bottom: 110px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 80px;
    }
}
.ratesBody {
}
.ratesRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md4+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.ratesRowLeft {
    flex: 0 1 722px;
    @media (max-width: $md3+px) {
        flex: 0 1 600px;
    }
    @media (max-width: $md4+px) {
        flex: 0 1 100%;
        margin-bottom: 30px;
    }
}
.ratesRowLeftTitle {
    max-width: 684px;
    margin-bottom: 30px;
}
.ratesRowLeftSubtitle {
}
.ratesRowLeftBtn {
    margin-top: 80px;
    @media (max-width: $md5+px) {
        margin-top: 50px;
    }
}
.ratesRowRight {
    flex: 0 1 417px;
    margin-right: 100px;
    @media (max-width: $md1+px) {
        margin-right: 0;
    }
    img {
        width: 100%;
    }
}
.mb15 {
    margin-bottom: 15px;
}
