@import "../../../styles/_var.scss";

.title {
    margin-bottom: 30px;
}

.textMb70 {
    margin-bottom: 70px;
}

.itemWrapLeft {
    padding-right: 50px;

    @media (max-width: $md2+px) {
        padding-right: 30px;
    }

    @media (max-width: $md4+px) {
        padding-right: 0px;
    }
}

.itemWrapRight {
    padding-left: 50px;

    @media (max-width: $md2+px) {
        padding-left: 30px;
    }

    @media (max-width: $md4+px) {
        padding-left: 0px;
    }
}

.text {
    margin-bottom: 15px;
}