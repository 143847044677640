@import "../../../styles/var";

.newsletters {
    position: relative;
    margin: 130px 0;
    @media (max-width: $md4+px) {
        margin: 110px 0;
    }
    @media (max-width: $md6+px) {
        margin: 80px 0 110px 0;
    }
    .content {
        position: relative;
        z-index: 5;
        max-width: 760px;
        @media (max-width: $md1+px) {
            max-width: 700px;
        }
        .text {
            margin: 30px 0 50px 0;
            display: flex;
            flex-direction: column;
            gap: 15px;
            @media (max-width: $md6+px) {
                margin: 30px 0 30px 0;
            }
            b {
                font-weight: 500;
            }
        }
        .form {
            display: flex;
            justify-content: space-between;
            gap: 50px;
            @media (max-width: $md6+px) {
                gap: 0;
            }
            input {
                // width: 340px;
                width: 100%;
                background: #334c65;
                padding: 12px;
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                color: #ffffff;
                &::placeholder {
                    color: #ffffff;
                }
                @media (max-width: $md6+px) {
                    font-size: 14px;
                    padding: 8px 12px;
                }
            }
            .btn {
            }
        }
    }
    img {
        position: absolute;
        z-index: 3;
        right: 0;
        top: 0;
        // width: 613px;
        @media (max-width: $md1+px) {
            width: 600px;
            top: 10px;
        }
        @media (max-width: $md2+px) {
            right: -40px;
            width: 500px;
            top: 60px;
        }
        @media (max-width: $md3+px) {
            width: 450px;
            right: -100px;
        }
        @media (max-width: $md4+px) {
            position: relative;
            display: flex;
            margin: 20px auto 0 auto;
            top: unset;
            right: unset;
        }
        @media (max-width: $md6+px) {
            width: 100%;
            margin-top: 15px;
        }
    }
}
