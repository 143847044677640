@import "../../../styles/_var.scss";
.rates {
    margin-bottom: 130px;
    @media (max-width: $md4+px) {
        margin-bottom: 110px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 80px;
    }
}
.ratesBody {
}
.ratesRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md4+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.ratesRowLeft {
    flex: 0 1 722px;
    @media (max-width: $md2+px) {
        flex: 0 1 600px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 500px;
    }
    @media (max-width: $md4+px) {
        flex: 0 1 100%;
        margin-bottom: 30px;
    }
}
.ratesRowLeftTitle {
    max-width: 500px;

    margin-bottom: 30px;
}
.ratesRowLeftSubtitle {
}
.ratesRowLeftBtn {
    margin-top: 80px;
    @media (max-width: $md5+px) {
        margin-top: 50px;
    }
}

.mb15 {
    margin-bottom: 15px;
}

.ratesRowRight {
    flex: 0 1 517px;
    @media (max-width: $md3+px) {
        flex: 0 1 450px;
    }
    @media (max-width: $md6+px) {
        flex: 0 1 339px;
    }
}
.ratesRowRightColumn {
    height: 114px;
    background: rgba(255, 253, 253, 0.6);

    box-shadow: 0px 5px 10px rgba(51, 48, 48, 0.25);

    border-radius: 16.8235px;
    position: relative;
    margin-bottom: 27px;
    cursor: pointer;
    @media (max-width: $md6+px) {
        height: 74.75px;
    }
    &:hover {
        .ratesRowRightColumnItemTitle {
            opacity: 0;
        }
        .ratesRowRightColumnItemSubtitle {
            opacity: 1;
        }
    }
    &:last-child {
        margin-bottom: 0;
    }
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        backdrop-filter: blur(5.25733px);
    }

    &:before {
        content: "";
        display: inline-block;
        left: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 100px;
        z-index: 1;
        height: 100px;
        @media (max-width: $md3+px) {
            height: 70px;
            width: 70px;
        }
        @media (max-width: $md6+px) {
            height: 50px;
            width: 50px;
        }
    }
    &:nth-child(1) {
        &:before {
            background: url("../../../assets/img/main/7/1.gif") 0 0 / contain no-repeat;
        }
    }
    &:nth-child(2) {
        &:before {
            background: url("../../../assets/img/main/7/2.gif") 0 0 / contain no-repeat;
            right: 20px;
            left: unset;
        }
    }
    &:nth-child(3) {
        &:before {
            background: url("../../../assets/img/main/7/3.gif") 0 0 / contain no-repeat;
        }
    }
}
.ratesRowRightColumnItem {
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    @media (max-width: $md4+px) {
        flex-direction: column;
    }
}
.ratesRowRightColumnItemTitle {
    transition: all 0.3s linear;
    @media (max-width: $md4+px) {
        opacity: 1 !important;
        margin-bottom: 5px;
    }
}
.ratesRowRightColumnItemSubtitle {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.3s linear;
    @media (max-width: $md4+px) {
        opacity: 1;
        position: relative;
        flex-direction: row;
        height: auto;
    }
    span {
        display: block;
    }
}
