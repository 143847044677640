@import '../../../styles/var';

.container {
    @media(max-width: $md3+px) {
        width: 100%!important;
        max-width: 100%!important;
    }
    @media(max-width: $md5+px) {
        padding: 0!important;
    }
}

.whyOpen {
    position: relative;
    margin-top: 130px;
    @media(max-width: $md4+px) {
        margin-top: 110px;
    }
    @media(max-width: $md6+px) {
        margin-top: 80px;
    }
    .title {
        text-align: center;
        @media(max-width: $md3+px) {
            padding: 0 40px;
        }
        @media(max-width: $md5+px) {
            padding: 0 18px;
        }
    }
    .contentWr {
        width: 100%;
        @media(max-width: $md3+px) {
            overflow: auto;
            overflow-y: hidden;
            padding: 0 20px 20px 20px;
        }
        @media(max-width: $md6+px) {
            overflow: hidden;
        }
    }
    .content {
        margin-top: 120px;
        display: flex;
        justify-content: space-around;
        @media(max-width: $md1+px) {
            justify-content: space-between;
        }
        @media(max-width: $md3+px) {
            width: 1150px;
        }
        @media(max-width: $md4+px) {
            width: 1000px;
            margin-top: 90px;
        }
        @media(max-width: $md6+px) {
            width: 100%;
            margin-top: 60px;
            flex-direction: column;
            gap: 70px;
            justify-content: unset;
        }
        .item  {
            width: 380px;
            @media(max-width: $md2+px) {
                width: 340px;
            }
            @media(max-width: $md4+px) {
                width: 277px;
            }
            @media(max-width: $md6+px) {
                margin: 0 auto;
                width: 253px;
            }
            &Active {
                .button {
                    transform: rotate(90deg);
                }
                .itemBottom {
                    transform: translateY(-30px);
                }
            }
            &Top {
                background: rgba(255, 253, 253, 0.6);
                box-shadow: 0px 5px 10px rgba(51, 48, 48, 0.25);
                backdrop-filter: blur(5px);
                border-radius: 16px;
                height: 230px;
                position: relative;
                z-index: 5;
                @media(max-width: $md2+px) {
                    height: 200px;
                }
                @media(max-width: $md4+px) {
                    height: 167px;
                }
                @media(max-width: $md6+px) {
                    height: 153px;
                }
                .itemTopImage {
                    position: absolute;
                    pointer-events: none;
                    &_01 {
                        width: 410px;
                        bottom: 0;
                        left: -15px;
                        @media(max-width: $md2+px) {
                            width: 340px;
                            left: 5px;
                        }
                        @media(max-width: $md4+px) {
                            width: 280px;
                            left: 2px;
                        }
                        @media(max-width: $md6+px) {
                            width: 273px;
                            left: -7px;
                        }
                    }
                    &_02 {
                        width: 269px;
                        bottom: -10px;
                        left: 64px;
                        @media(max-width: $md2+px) {
                            width: 210px;
                            left: 75px;
                            bottom: 0;
                        }
                        @media(max-width: $md4+px) {
                            width: 160px;
                            left: 65px;
                        }
                        @media(max-width: $md6+px) {
                            width: 179px;
                            left: 42px;
                            bottom: -3px;
                        }
                    }
                    &_03 {
                        width: 233px;
                        bottom: -10px;
                        left: 69px;
                        @media(max-width: $md2+px) {
                            width: 200px;
                            bottom: 0;
                            left: 72px;
                        }
                        @media(max-width: $md4+px) {
                            width: 140px;
                            left: 67px;
                        }
                        @media(max-width: $md6+px) {
                            width: 170px;
                            bottom: -3px;
                            left: 46px;
                        }
                    }
                }
                .button {
                    position: absolute;
                    z-index: 10;
                    left: 14px;
                    bottom: -12px;
                    height: 50px;
                    width: 50px;
                    border-radius: 100px;
                    background: rgba(255, 253, 253, 0.75);
                    box-shadow: 0px 5px 10px rgba(51, 48, 48, 0.25);
                    backdrop-filter: blur(6px);
                    cursor: pointer;
                    transition: all .3s;
                    @media(max-width: $md6+px) {
                        width: 34px;
                        height: 34px;
                        bottom: -8px;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        background-image: url('../../../assets/img/StartPage/WhyOpen/plus.svg');
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: 22px;
                        height: 22px;
                        left: calc(50% - 11px);
                        top: calc(50% - 11px);
                        @media(max-width: $md6+px) {
                            width: 16px;
                            height: 16px;
                            left: calc(50% - 8px);
                            top: calc(50% - 8px);
                        }
                    }
                }
            }
            &Center {
                background: #CAE7FF;
                border-radius: 16px 16px 0 0;
                padding: 20px 14px 50px 14px;
                position: relative;
                z-index: 3;
                padding: 15px 10px 40px 10px;
                ul {
                    padding-left: 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 22px;
                    color: #373737;
                    @media(max-width: $md2+px) {
                        font-size: 14px;
                        line-height: 20px;
                    }
                    @media(max-width: $md4+px) {
                        font-size: 11px;
                        line-height: 16px;
                        gap: 6px;
                    }
                    li {
                        list-style-type: disc;
                        list-style-position: outside;
                    }
                }
            }
            &Bottom {
                z-index: 4;
                position: relative;
                transition: all .5s;
                background: #2196F3;
                border-radius: 16px;
                color: #FFFFFF;
                font-weight: 800;
                font-size: 20px;
                line-height: normal;
                padding: 15px 0;
                text-align: center;
                @media(max-width: $md2+px) {
                    font-size: 16px;
                }
                @media(max-width: $md4+px) {
                    font-size: 14px;
                }
                @media(max-width: $md6+px) {
                    font-size: 13px;
                    padding: 10px 0;
                    border-radius: 10px;
                }
            }
        }
    }
}