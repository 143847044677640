@import "../../../styles/var";

.banner {
    position: relative;
    margin-top: 140px;
    @media (max-width: $md6+px) {
        margin-top: 130px;
    }
    .content {
        position: relative;
        z-index: 10;
        max-width: 722px;
        @media (max-width: $md2+px) {
            max-width: 600px;
        }
        .title {
            padding-top: 120px;
            @media (max-width: $md1+px) {
                padding-top: 70px;
            }

            @media (max-width: $md3+px) {
                padding-top: 50px;
            }
            @media (max-width: $md6+px) {
                padding-top: 0;
                font-size: 25px;
                line-height: 38px;
            }
        }
        .text_01 {
            margin: 30px 0 15px 0;
            font-weight: 500;
        }
        .btn {
            margin-top: 78px;
            @media (max-width: $md3+px) {
                margin-top: 40px;
            }
            @media (max-width: $md6+px) {
                text-align: center;
                margin-top: 30px;
            }
        }
    }
    .rect_01 {
        background: rgba(255, 253, 253, 0.6);
        box-shadow: 0px 5px 10px rgba(51, 48, 48, 0.25);
        backdrop-filter: blur(5.25733px);
        border-radius: 16.8235px;
        transform: rotate(45deg);
        width: 239px;
        height: 239px;
        position: absolute;
        z-index: 3;
        top: 80px;
        right: calc(50% - 280px);
        @media (max-width: $md1+px) {
            width: 200px;
            height: 200px;
        }
        @media (max-width: $md2+px) {
            width: 180px;
            height: 180px;
        }
        @media (max-width: $md3+px) {
            top: unset;
            right: unset;
            left: 40px;
            bottom: 160px;
        }
        @media (max-width: 600px) {
            width: 98px;
            height: 98px;
            left: 20px;
            border-radius: 6.94669px;
            bottom: 120px;
            box-shadow: 0px 2.06459px 4.12917px rgba(51, 48, 48, 0.25);
        }
    }
    .rect_02 {
        z-index: 3;
        position: absolute;
        width: 104px;
        height: 104px;
        background: rgba(255, 253, 253, 0.6);
        box-shadow: 0px 5px 10px rgba(51, 48, 48, 0.25);
        backdrop-filter: blur(5.25733px);
        border-radius: 16.8235px;
        transform: rotate(45deg);
        top: 0;
        right: calc(50% - 340px);
        @media (max-width: $md1+px) {
            width: 90px;
            height: 90px;
        }
        @media (max-width: $md3+px) {
            width: 86px;
            height: 86px;
            top: unset;
            right: unset;
            left: 210px;
            bottom: 330px;
        }
        @media (max-width: 600px) {
            width: 50px;
            height: 50px;
            left: 100px;
            bottom: 205px;
            border-radius: 6.94669px;
            box-shadow: 0px 2.06459px 4.12917px rgba(51, 48, 48, 0.25);
        }
    }
    .rect_03 {
        background: rgba(255, 253, 253, 0.6);
        box-shadow: 0px 5px 10px rgba(51, 48, 48, 0.25);
        backdrop-filter: blur(5.25733px);
        border-radius: 16.8235px;
        transform: rotate(45deg);
        width: 239px;
        height: 239px;
        position: absolute;
        z-index: 3;
        top: 240px;
        right: calc(50% - 750px);
        @media (max-width: $md1+px) {
            width: 200px;
            height: 200px;
            right: calc(50% - 600px);
            top: 200px;
        }
        @media (max-width: $md3+px) {
            top: unset;
            bottom: 50px;
            right: 40px;
        }
        @media (max-width: 600px) {
            width: 98px;
            height: 98px;
            right: 20px;
            border-radius: 6.94669px;
            box-shadow: 0px 2.06459px 4.12917px rgba(51, 48, 48, 0.25);
        }
    }
    .rect_04 {
        @media (max-width: $md6+px) {
            position: absolute;
            z-index: 3;
            width: 200px;
            height: 200px;
            top: -90px;
            left: -30px;
            background: rgba(255, 253, 253, 0.6);
            box-shadow: 0px 2.28485px 4.5697px rgba(51, 48, 48, 0.25);
            backdrop-filter: blur(2.40244px);
            border-radius: 7.68781px;
            transform: rotate(45deg);
        }
    }
    .rect_05 {
        @media (max-width: $md6+px) {
            position: absolute;
            z-index: 3;
            width: 100px;
            height: 100px;
            top: 105px;
            left: 105px;
            background: rgba(255, 253, 253, 0.6);
            box-shadow: 0px 2.28485px 4.5697px rgba(51, 48, 48, 0.25);
            backdrop-filter: blur(2.40244px);
            border-radius: 7.68781px;
            transform: rotate(45deg);
        }
    }
    .bear {
        width: 740px;
        position: absolute;
        z-index: 6;
        top: 20px;
        right: 40px;
        @media (max-width: $md1+px) {
            width: 570px;
            right: 0;
            top: 30px;
        }
        @media (max-width: $md2+px) {
            width: 500px;
            top: 50px;
        }
        @media (max-width: $md3+px) {
            position: relative;
            display: flex;
            margin: 40px auto 0 auto;
            width: 600px;
        }
        @media (max-width: $md5+px) {
            width: 550px;
        }
        @media (max-width: 600px) {
            width: 340px;
            margin-top: 30px;
            top: unset;
        }
    }
}
.hoverAnim {
    @media (max-width: $md1+px) {
        transform: scale(0.8) translate(300px, -530px);
    }
    @media (max-width: $md2+px) {
        transform: scale(0.7) translate(300px, -650px);
    }
    &.hover {
        .pc_bear {
            transform: translate(0, 0) rotate(0);
        }
        .whiteSquare1 {
            transform: translate(0, 0) rotate(0);
        }
        .whiteSquare2 {
            transform: translate(0, 0) rotate(0);
        }
        .whiteSquare3 {
            transform: translate(0, 0) rotate(0);
        }
        .blueSquare1 {
            transform: translate(0, 0) rotate(0);
            &::after {
                transform: translate(0, 0) rotate(0);
            }
        }
        .blueSquare2 {
            transform: translate(0, 0) rotate(0);
            &::after {
                transform: translate(0, 0) rotate(0);
            }
        }
        .blueSquare3 {
            transform: translate(0, 0) rotate(0);
            &::after {
                transform: translate(0, 0) rotate(0);
            }
        }
    }
}
.pc_bear {
    width: 536.25px;
    height: 536.25px;
    right: 100px;
    position: absolute;
    top: 0;

    z-index: 5;
    background: url("../../../assets/img/StartPage/Banner/bearM.png") 0 0 / contain no-repeat;
}
.whiteSquare1 {
    width: 154.63px;
    height: 154.63px;
    right: 445px;
    position: absolute;
    top: -20px;
    background: url("../../../assets/img/StartPage/Banner/square1.png") 0 0 / contain no-repeat;
}
.whiteSquare2 {
    width: 320px;
    height: 320px;
    right: 495px;
    position: absolute;
    top: 40px;
    transition: all 0.3s linear;
    transform: rotate(-17deg);
    background: url("../../../assets/img/StartPage/Banner/square2.png") 0 0 / contain no-repeat;
}
.whiteSquare3 {
    width: 320px;
    height: 320px;

    right: 5px;
    position: absolute;
    top: 210px;
    transition: all 0.3s linear;
    transform: rotate(-25deg);
    background: url("../../../assets/img/StartPage/Banner/square3.png") 0 0 / contain no-repeat;
}
.blueSquare1 {
    width: 171px;
    height: 143px;
    right: 615px;
    top: 190px;
    position: absolute;

    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        transition: all 0.3s linear;
        transform: rotate(-45deg) translate(4px, -10px);

        background: url("../../../assets/img/StartPage/Banner/blue1.png") 0 0 / contain no-repeat;
    }
    &:before {
        content: "";
        display: inline-block;
        left: -10px;
        top: 8px;
        position: absolute;
        width: 95%;
        height: 95%;
        z-index: 1;
        background: url("../../../assets/img/StartPage/Banner/blue11.png") 0 0 / contain no-repeat;
    }
}
.blueSquare2 {
    width: 171px;
    height: 143px;
    right: 15px;
    top: 70px;
    position: absolute;

    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        transition: all 0.3s linear;
        transform: rotate(-90deg) translate(10px, -10px);
        background: url("../../../assets/img/StartPage/Banner/blue2.png") 0 0 / contain no-repeat;
    }

    &:before {
        content: "";
        display: inline-block;
        left: -10px;
        top: 8px;
        position: absolute;
        width: 95%;
        height: 95%;
        z-index: 1;
        background: url("../../../assets/img/StartPage/Banner/blue22.png") 0 0 / contain no-repeat;
    }
}
.blueSquare3 {
    width: 171px;
    height: 143px;
    right: 425px;
    top: 410px;
    position: absolute;

    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        transition: all 0.3s linear;
        transform: rotate(-15deg) translate(2px, -5px);
        background: url("../../../assets/img/StartPage/Banner/blue3.png") 0 0 / contain no-repeat;
    }
    &:before {
        content: "";
        display: inline-block;
        left: 4px;
        top: 1px;
        position: absolute;
        width: 95%;
        height: 95%;
        z-index: 1;
        background: url("../../../assets/img/StartPage/Banner/blue33.png") 0 0 / contain no-repeat;
    }
}
