@import "../../../styles/_var.scss";

.trendAnalysisContainer {
    position: relative;
    z-index: 10;
    margin-top: 130px;
    background-color: #f5f9ff;
    padding: 50px 0 0;

    @media (max-width: $md4+px) {
        margin-top: 110px;
    }

    @media (max-width: $md2+px) {
        margin-top: 110px;
    }

    @media (max-width: $md6+px) {
        margin-top: 80px;
    }
}

.title {
    margin-bottom: 30px;
}

.textMb70 {
    margin-bottom: 70px;
}

.row {
    margin-bottom: 70px;
}

.itemWrapLeft {
    padding-right: 90px;

    @media (max-width: $md2+px) {
        padding-right: 30px;
    }

    @media (max-width: $md4+px) {
        padding-right: 0px;
    }
}

.imgWrap {
    display: flex;
    align-items: flex-end;
    justify-content: center;

    @media (max-width: $md4+px) {
        margin-top: 40px;
    }

    img {
        max-width: 100%;
        height: auto;
    }
}

.bannerBtnWrap {
    @media (max-width: $md5+px) {
        display: none;
    }
}

.bannerBtnWrapMobile {
    display: none;

    @media (max-width: $md5+px) {
        display: flex;
        justify-content: center;
    }
}