@import "../../../styles/_var.scss";

.bannerTitle {
    margin-bottom: 30px;
}

.itemWrapLeft {
    padding-right: 50px;

    @media (max-width: $md2+px) {
        padding-right: 30px;
    }

    @media (max-width: $md4+px) {
        padding-right: 0px;
    }
}

.itemWrapRight {
    padding-left: 50px;

    @media (max-width: $md2+px) {
        padding-left: 30px;
    }

    @media (max-width: $md4+px) {
        padding-left: 0px;
    }
}

.bannerText {
    margin-bottom: 15px;
}

.bannerRowMb70 {
    margin-bottom: 70px;

    @media (max-width: $md4+px) {
        margin-bottom: 50px;
    }
}

.bannerBtnWrap {
    @media (max-width: $md5+px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}