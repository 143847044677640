@import '../../../styles/var';

.accounts {
    position: relative;
    margin-top: 130px;

    @media(max-width: $md4+px) {
        margin-top: 110px;
    }

    @media(max-width: $md6+px) {
        margin-top: 80px;
    }

    .tableWrap {
        position: relative;
        max-width: 1000px;
        overflow: auto;
        margin: 0 auto;

        @media(max-width: $md5+px) {
            width: calc(100% + 36px);
            transform: translateX(-18px);
            padding-left: 18px;
            padding-right: 18px;
            padding-bottom: 10px;
        }

        table {
            width: 100%;
            min-width: 850px;
            border-spacing: 0;
            table-layout: fixed;
            color: #000;
            font-family: $fontfamily;
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
            @media(max-width: $md4+px) {
                font-size: 14px;
            }

            thead {}

            tbody {}
        }

        tr {
            text-align: left;

            td,
            th {
                border-bottom: 1px solid #000;
                padding: 11px 20px 11px 0;
                background: #fff;
            }
        }
    }
}