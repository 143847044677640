@import "../../../styles/_var.scss";
.three {
    margin-bottom: 60px;

    @media (max-width: $md6+px) {
        margin-bottom: 50px;
    }
    position: relative;
    @media (max-width: $md5+px) {
        &:before {
            content: "";
            display: inline-block;
            left: 0;
            bottom: -60px;
            z-index: 200;
            position: absolute;
            width: 356px;
            z-index: 1;
            height: 401px;
            background: url("../../../assets/img/main/4/2.png") 0 0 / cover no-repeat;
        }
    }
}
.threeBody {
    overflow: hidden;
    position: relative;
    z-index: 2;
    &:after {
        content: "";
        display: inline-block;
        right: -50px;
        top: -90px;
        position: absolute;
        width: 648px;
        height: 829px;

        background: url("../../../assets/img/main/4/1.png") 0 0 / contain no-repeat;
        @media (max-width: $md5+px) {
            left: 0;
            right: unset;
            width: 448px;
            height: 629px;
            top: -70px;
        }
        @media (max-width: $md6+px) {
            width: 348px;
            height: 529px;
            top: 0;
        }
    }
}
.threeRow {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    margin-bottom: 130px;
    @media (max-width: $md1+px) {
        margin-bottom: 100px;
    }
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
    @media (max-width: $md5+px) {
        margin-bottom: 80px;
    }
}
.threeRowLeft,
.threeRowRight {
    flex: 0 1 722px;
    @media (max-width: $md1+px) {
        flex: 0 1 600px;
    }
    @media (max-width: $md2+px) {
        flex: 0 1 570px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
    }
}
.threeRowLeft {
    @media (max-width: $md3+px) {
        margin-bottom: 30px;
    }
}
.threeRowLeftTitle {
}

.threeRowLeftSubtitle {
}

.threeRowRight {
}
.threeRowRightTitle {
}
.threeRowRightSubtitle {
}
.threeBottom {
    position: relative;
    z-index: 2;
}
.threeBottomTitle {
    max-width: 948px;
    text-align: center;
    margin: 0 auto;
    z-index: 2;
    position: relative;
}
.threeBottomSubtitle {
    max-width: 740px;
    text-align: center;
    z-index: 2;
    position: relative;
    margin: 0 auto;
}
.mb20 {
    margin-bottom: 20px;
}
.mb15 {
    margin-bottom: 15px;
}
.mb40 {
    margin-bottom: 40px;
    @media (max-width: $md5+px) {
        margin-bottom: 20px;
    }
}
