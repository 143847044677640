@import '../../../styles/var';

.banner {
    position: relative;
    margin-top: 229px;
    @media(max-width: $md4+px) {
        margin-top: 185px;
    }
    @media(max-width: $md6+px) {
        margin-top: 115px;
    }
    .content {
        position: relative;
        z-index: 5;
        max-width: 719px;
        @media(max-width: $md1+px) {
            max-width: 680px;
        }
        .title {
            @media(max-width: $md6+px) {
                font-size: 25px;
                line-height: 38px;
            }
        }
        .text {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin: 30px 0 78px 0;
            @media(max-width: $md4+px) {
                margin-bottom: 58px;
            }
            @media(max-width: $md6+px) {
                margin-bottom: 40px;
            }
        }
        .btn {
            @media(max-width: $md5+px) {
                text-align: center;
            }
        }
    }
    img {
        position: absolute;
        z-index: 2;
        top: -70px;
        right: 0;
        @media(max-width: $md1+px) {
            width: 570px;
            top: -20px;
        }
        @media(max-width: $md2+px) {
            right: -50px;
        }
        @media(max-width: $md3+px) {
            position: relative;
            display: flex;
            right: unset;
            top: unset;
            margin: 20px auto 0 auto;
        }
        @media(max-width: $md5+px) {
            width: 90%;
        }
    }
    .rect_01, .rect_02, .rect_03 {
        background: rgba(255, 253, 253, 0.6);
        box-shadow: 0px 5px 10px rgba(51, 48, 48, 0.25);
        backdrop-filter: blur(5.25733px);
        border-radius: 16.8235px;
        transform: rotate(45deg);
        position: absolute;
        z-index: 2;
        @media(max-width: $md4+px) {
            background: rgba(255, 253, 253, 0.6);
            box-shadow: 0px 2.21486px 4.42971px rgba(51, 48, 48, 0.25);
            backdrop-filter: blur(2.32884px);
            border-radius: 7.4523px;
            transform: rotate(-45deg);
        }
    }
    .rect_01 {
        width: 397px;
        height: 397px;
        top: -50px;
        left: -200px;
        @media(max-width: $md1+px) {
            width: 320px;
            height: 320px;
        }
        @media(max-width: $md4+px) {
            width: 196px;
            height: 196px;
            top: 90px;
            left: -90px;
        }
    }
    .rect_02 {
        width: 282px;
        height: 282px;
        top: 230px;
        left: 150px;
        @media(max-width: $md1+px) {
            width: 200px;
            height: 200px;
            top: 180px;
            left: 100px;
        }
        @media(max-width: $md4+px) {
            width: 138px;
            height: 138px;
            top: 5px;
            left: 90px;
        }
    }
    .rect_03 {
        @media(max-width: $md4+px) {
            width: 99px;
            height: 99px;
            left: 220px;
            top: 110px;
        }
    }
}