@import '../../../styles/var';

.wrap {
    padding-top: 229px;
    
    @media(max-width: $md4+px) {
        padding-top: 185px;
    }
    
    @media(max-width: $md6+px) {
        padding-top: 115px;
    }
}

.banner {
    position: relative;

    .content {
        position: relative;
        z-index: 5;
        max-width: 719px;

        @media(max-width: $md1+px) {
            max-width: 680px;
        }

        .title {
            @media(max-width: $md6+px) {
                font-size: 25px;
                line-height: 38px;
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin: 30px 0 78px 0;

            @media(max-width: $md4+px) {
                margin-bottom: 58px;
            }

            @media(max-width: $md6+px) {
                margin-bottom: 40px;
            }
        }

        .btn {
            @media(max-width: $md5+px) {
                text-align: center;
            }
        }
    }

    img {
        position: absolute;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        right: 80px;
        width: 522px;

        @media(max-width: $md1+px) {
            right: 0;
            width: 480px;
        }

        @media(max-width: $md2+px) {}

        @media(max-width: $md3+px) {
            transform: none;
            position: relative;
            display: flex;
            right: unset;
            top: unset;
            margin: 30px auto 0 auto;
        }

        @media(max-width: $md5+px) {
            width: 100%;
            max-width: 480px;
        }
    }
}