@import '../../../styles/var';

.theOnlineBroker {
    position: relative;
    margin: 130px 0;
    @media(max-width: $md4+px) {
        margin: 110px 0;
    }
    @media(max-width: $md6+px) {
        margin: 80px 0;
    }
    .title {
        max-width: 729px;
    }
    .content {
        display: flex;
        justify-content: space-between;
        margin: 30px 0 0 0;
        @media(max-width: $md3+px) {
            flex-direction: column;
            gap: 15px;
            justify-content: unset;
        }
        .col {
            max-width: 719px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            @media(max-width: $md1+px) {
                max-width: 600px;
            }
            @media(max-width: $md2+px) {
                max-width: 560px;
            }
            @media(max-width: $md3+px) {
                max-width: 100%;
            }
        }
    }
}