@import "../../../styles/_var.scss";
.NewLettersRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}
.NewLettersRowRight {
    flex: 0 1 722px;
    @media (max-width: $md1+px) {
        flex: 0 1 600px;
    }
    @media (max-width: $md2+px) {
        flex: 0 1 550px;
    }

    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-bottom: 0px !important;
    }
}
.NewLettersRowRightTitle {
    margin-bottom: 30px;
    @media (max-width: $md3+px) {
        max-width: 100%;
    }
}
.NewLettersRowRightSubtitle {
}
.mb15 {
    margin-bottom: 15px;
}

.NewLettersRowLeft {
    flex: 0 1 722px;
    @media (max-width: $md1+px) {
        flex: 0 1 600px;
    }
    @media (max-width: $md2+px) {
        flex: 0 1 550px;
    }

    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-bottom: 50px !important;
    }
}
.NewLettersRowLeftTitle {
    margin-bottom: 30px;
    @media (max-width: $md3+px) {
        max-width: 100%;
    }
}
.NewLettersRowLeftSubtitle {
    &.fw600 {
        font-weight: 600 !important;
    }
}
.mb15 {
    margin-bottom: 15px;
}
.NewLetters {
    margin-bottom: 130px;
    @media (max-width: $md4+px) {
        margin-bottom: 110px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 80px;
    }
}
.NewLettersBody {
}
.NewLettersRow {
}
.NewLettersRowLeft,
.NewLettersRowRight {
    display: flex;
    flex-direction: column;
}
.NewLettersRowLeftWrapper,
.NewLettersRowRightWrapper {
    margin-bottom: 70px;
    @media (max-width: $md4+px) {
        margin-bottom: 30px;
    }

    flex: 0 1 100%;
}
.NewLettersRowLeftTitle {
}

.NewLettersRowLeftSubtitle {
}

.NewLettersRowLeftBot {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        width: 500px;
    }
    @media (max-width: 570px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
}
.NewLettersRowLeftBotEmail {
    width: 379px;
    height: 50px;
    @media (max-width: $md2+px) {
        width: 300px;
    }
    @media (max-width: 570px) {
        margin-bottom: 20px;
        width: 100%;
    }
    background: #334c65;
    position: relative;
    input {
        font-weight: 500;
        line-height: 50px;
        color: #ffffff;
        background-color: transparent;
        padding: 0 13px;
        &::placeholder {
            color: #ffffff;
        }
    }
}
.NewLettersRowLeftSubs {
}
.NewLettersRowRight {
}
.NewLettersRowRightWrapper {
}
.NewLettersRowRightTitle {
}
.NewLettersRowRightSubtitle {
}
.NewLettersRowRightBot {
}
.NewLettersRowLeftSubs,
.NewLettersRowRightBotOpen {
    position: relative;
    top: 7px;
}

.NewLettersRowRightBotOpen {
    @media (max-width: 570px) {
        margin: 0 auto;
    }
}
