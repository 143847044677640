@import "../../../styles/_var.scss";
.withdrawal {
    margin-bottom: 130px;
    @media (max-width: $md4+px) {
        margin-bottom: 110px;
    }
    @media (max-width: $md2+px) {
        margin-bottom: 110px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 80px;
    }
}
.withdrawalTitle {
    margin: 0 auto 35px auto;
    text-align: center;
}
.withdrawalSubtitle {
    text-align: center;
    max-width: 792px;
    color: #373737;

    font-weight: 500;

    margin: 0 auto 60px auto;
    @media (max-width: $md5+px) {
        margin-bottom: 30px;
    }
}
.withdrawalBody {
}
.withdrawalRow {
    display: flex;
    justify-content: space-between;

    border-bottom: 1px solid #aaaaaa;
    padding: 13px 0;
    @media (max-width: $md4+px) {
        flex-wrap: wrap;
    }
}

.withdrawalRowRight {
    flex: 0 1 613px;
    @media (max-width: $md4+px) {
        margin: 30px auto 0 auto;
    }
    img {
        width: 100%;
    }
}
.withdrawalRowColumn {
    &:nth-child(1) {
        flex: 0 1 110px;
    }
    &:nth-child(2) {
        flex: 0 1 110px;
    }
    &:nth-child(3) {
        flex: 0 1 110px;
    }
    &:nth-child(4) {
        flex: 0 1 315px;
    }
}
.withdrawalText {
    margin-top: 50px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    max-width: 1150px;
    color: #373737;
    @media (max-width: $md5+px) {
        margin-top: 30px;
    }
    @media (max-width: $md6+px) {
        font-size: 12px;
        line-height: 18px;
    }
}
.fw600 {
    font-weight: 600;
}
.withdrawalWrapScroll {
    overflow: auto;
    padding-bottom: 20px;
    &::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 10px;
        border: 1px solid #56bfef;
    }
    &::-webkit-scrollbar {
        height: 6px;
        background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 25px;
        background-color: #56bfef;
    }
}
.withdrawalWrap {
    width: 715px;
    margin: 0 auto;
}
