@import "../../../styles/var";

.openingAnAccount {
    position: relative;
    margin-top: 130px;
    @media (max-width: $md4+px) {
        margin-top: 110px;
    }
    @media (max-width: $md6+px) {
        margin-top: 80px;
    }
    .title {
        text-align: center;
    }
    .contentMob {
        margin-top: 40px;
        display: none;
        @media (max-width: $md3+px) {
            display: flex;
            gap: 20px;
            flex-direction: column;
        }
        @media (max-width: $md4+px) {
            margin-top: 30px;
        }
        .step {
            text-align: center;
            max-width: 420px;
            margin: 0 auto;
            &Img {
                height: 100px;
                @media (max-width: $md5+px) {
                    height: 80px;
                }
            }
            &Title {
                font-weight: 600;
                margin: 20px 0 15px 0;
                @media (max-width: $md6+px) {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                }
            }
            &Text {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #373737;
                @media (max-width: $md4+px) {
                    font-size: 14px;
                }
            }
        }
    }
    .content {
        position: relative;
        margin-top: 80px;
        @media (max-width: $md1+px) {
            margin-top: 60px;
        }
        @media (max-width: $md3+px) {
            display: none;
        }
        .stepsImg {
            position: relative;
            z-index: 5;
            display: flex;
            margin: 0 auto;
            width: 490px;
            @media (max-width: $md2+px) {
                width: 400px;
            }
            transition: all 0.3s linear;
            top: 100px;
            @media (max-width: $md3+px) {
                top: 0;
            }
        }
        .step {
            position: absolute;
            z-index: 10;
            &_01 {
                width: 411px;
                top: 25px;
                right: calc(50% - 450px);
                @media (max-width: $md2+px) {
                    top: 0;
                }
            }
            &_02 {
                width: 328px;
                top: 110px;
                right: calc(50% + 250px);
                @media (max-width: $md2+px) {
                    top: 80px;
                    right: calc(50% + 200px);
                }
            }
            &_03 {
                width: 392px;
                top: 230px;
                right: calc(50% - 665px);
                @media (max-width: $md2+px) {
                    width: 350px;
                    top: 180px;
                    right: calc(50% - 585px);
                }
            }
            &Title {
                font-weight: 600;
            }
            &Text {
                font-weight: 400;
                font-size: 15px;
                line-height: 22px;
                color: #373737;
                margin-top: 10px;
            }
        }
    }
}
