@import '../../../styles/var';

.theOnline {
    position: relative;
    margin-top: 130px;
    background: #2196F3;
    padding: 50px 0;
    @media(max-width: $md1+px) {
        margin-top: 110px;
    }
    @media(max-width: $md6+px) {
        margin-top: 80px;
        padding: 30px 0;
    }
    .bg {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../../assets/img/AboutPage/the_online.png');
        background-repeat: no-repeat;
        background-position: center center;
        @media(max-width: $md2+px) {
            background-size: contain;
        }
    }
    .title {
        position: relative;
        z-index: 5;
        color: #FFFFFF;
    }
    .content {
        margin: 30px 0 70px 0;
        position: relative;
        z-index: 5;
        color: #FFFFFF;
        display: flex;
        justify-content: space-between;
        @media(max-width: $md1+px) {
            margin: 30px 0 60px 0;
        }
        @media(max-width: $md3+px) {
            flex-direction: column;
            gap: 15px;
            justify-content: unset;
        }
        .col {
            max-width: 719px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            @media(max-width: $md1+px) {
                max-width: 650px;
            }
            @media(max-width: $md2+px) {
                max-width: 580px;
            }
            @media(max-width: $md3+px) {
                max-width: 100%;
            }
        }
        ul {
            padding-left: 20px;
            @media(max-width: $md6+px) {
                gap: 10px!important;
            }
            li {
                list-style-type: disc;
                list-style-position: outside;
            }
        }
    }
    .btn {
        position: relative;
        z-index: 5;
        @media(max-width: $md6+px) {
            text-align: center;
        }
    }
}