@import "../../../styles/_var.scss";
.invest {
    margin-bottom: 130px;
    @media (max-width: $md4+px) {
        margin-bottom: 110px;
    }
    @media (max-width: $md2+px) {
        margin-bottom: 110px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 80px;
    }
}
.investBody {
}
.investRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md4+px) {
        flex-wrap: wrap;
    }
}
.investRowLeft {
    flex: 0 0 1140px;
    @media (max-width: $md1+px) {
        flex: 0 1 900px;
    }
}
.investRowLeftTitle {
    max-width: 720px;
    margin-bottom: 30px;
}
.investRowLeftSubtitle {
    font-weight: 500;
    margin-bottom: 15px;
}
.investRowLeftList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media(max-width: $md3+px) {
        display: flex;
        flex-direction: column;
    }
}
.investRowLeftListItem {
    margin-bottom: 15px;
    position: relative;
    padding-left: 20px;

    @media (max-width: $md1+px) {
    }

    &:after {
        content: "";
        display: inline-block;
        left: 5px;
        top: 12px;
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #373737;
        @media (max-width: $md4+px) {
            top: 10px;
        }
        @media (max-width: $md6+px) {
            top: 7px;
        }
    }
}
.investRowRight {
    flex: 0 1 613px;
    @media (max-width: $md4+px) {
        margin: 30px auto 0 auto;
    }
    img {
        width: 100%;
    }
}
