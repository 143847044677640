@import "../../../styles/var";

.button {
    position: relative;
    display: inline-flex;

    border: 1px solid #334C65;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    cursor: pointer;

    color: #FFFFFF;
    font-family: $fontfamily;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: normal;

    @media(max-width: $md6+px) {
        font-size: 14px;
    }

    span {
        padding: 11px 30px;
        background: #334C65;
        transform: translateX(8px) translateY(-8px);
        transition: all .2s;
        @media(max-width: $md6+px) {
            padding: 10px 20px;
        }
    }
    &:hover, span:hover {
        span {
            transform: translateX(3px) translateY(-3px);
        }
    }
    &:active, span:active {
        span {
            transition: all .1s;
            transform: translateX(0px) translateY(0px);
        }
    }

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
