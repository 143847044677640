@import "../../../styles/_var.scss";
.firstRowLeftTabs {
    position: relative;
    padding-bottom: 95px;
    @media (max-width: $md4+px) {
        padding-bottom: 70px;
    }
    @media (max-width: $md5+px) {
        padding-bottom: 0;
    }
}
.firstRowLeftTab {
    @media (max-width: $md5+px) {
        margin-bottom: 30px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 20px;
    }
    &.active {
        .firstRowLeftTabTitle {
            color: #2196f3;
            padding-left: 55px;
            overflow: visible;
            &:before {
                left: 0;
            }
        }
        .firstRowLeftTabText {
            opacity: 1;
            position: absolute;

            @media (max-width: $md5+px) {
                position: relative;
            }
            left: 0;
            span {
                opacity: 1;
                bottom: 0;
            }
        }
    }
}
.firstRowLeftTabTitle {
    overflow: hidden;

    transition: all 0.3s linear;
    margin-bottom: 15px;
    cursor: pointer;
    position: relative;
    @media (max-width: $md5+px) {
        margin-bottom: 15px;
        color: #2196f3;
        color: #2196f3;
        padding-left: 30px !important;
        overflow: visible;
        &:before {
            left: 0 !important;
        }
        &:before {
            display: none;
        }
    }

    &:before {
        content: "";
        display: inline-block;
        left: -50px;
        top: -4px;

        margin: auto;
        position: absolute;
        width: 40px;
        overflow: hidden;
        height: 40px;
        transition: all 0.3s linear;
        background: url("../../../assets/img/main/1/arrow.svg") 0 center / contain no-repeat;
        animation: 1s infinite alternate slidein;
        @media (max-width: $md1+px) {
            top: -2px;
        }
        @media (max-width: $md5+px) {
            animation: unset;
            width: 20px;
            height: 20px;
            top: 5px;
        }
        @media (max-width: $md6+px) {
            top: 3px;
        }
    }
}
@keyframes slidein {
    from {
        transform: translateX(20%);
    }

    to {
        transform: translateX(-20%);
    }
}
.firstRowLeftTabText {
    opacity: 0;
    max-width: 555px;
    pointer-events: none;
    transition: all 0.3s linear;
    bottom: 0;
    position: absolute;
    @media (max-width: $md5+px) {
        position: relative;
        opacity: 1;
        max-width: 100%;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
    span {
        position: relative;
        bottom: -100px;
        transition: all 0.3s linear;
        @media (max-width: $md5+px) {
            bottom: 0;
        }
    }
}

.first {
    padding: 200px 0 0 0;
    margin-bottom: 100px;
    @media (max-width: $md5+px) {
        padding: 180px 0 0 0;
    }
    @media (max-width: $md6+px) {
        padding: 117px 0 0 0;
        margin-bottom: 70px;
    }
    position: relative;
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        width: 663px;
        height: 988px;
        background: url("../../../assets/img/main/1/bg.png") 0 0 / contain no-repeat;
        @media (max-width: $md1+px) {
            left: -100px;
        }
        @media (max-width: $md3+px) {
            top: -350px;
        }
        @media (max-width: $md4+px) {
            top: -400px;
        }
        @media (max-width: $md5+px) {
            left: -100px;
            top: -100px;
            width: 463px;
            height: 788px;
        }
        @media (max-width: $md6+px) {
            top: -230px;
        }
    }
}
.firstBody {
    position: relative;
    z-index: 2;
}
.firstRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md1+px) {
        align-items: center;
    }
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}
.firstRowLeft {
    flex: 0 0 742px;
    margin-right: 58px;
    @media (max-width: $md1+px) {
        flex: 0 0 600px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-right: 0;
    }
}
.firstRowLeftTitle {
    margin-bottom: 30px;
}
.firstRowLeftSubtitle {
    margin-bottom: 80px;
    font-weight: 600;
    color: #373737;
    @media (max-width: $md5+px) {
        margin-bottom: 30px;
    }
}
.firstRowLeftSignBtn {
    margin-bottom: 80px;
    @media (max-width: $md3+px) {
        margin-bottom: 20px;
    }
}
.firstRowLeftTabs {
    @media (max-width: $md3+px) {
        display: none;
    }
    &.mob {
        display: none;
        @media (max-width: $md3+px) {
            display: block;
        }
    }
}
.firstRowLeftTab {
}
.firstRowLeftTabTitle {
}
.firstRowLeftTabText {
}
.firstRowRight {
    flex: 0 1 100%;
    img {
        width: 100%;
    }
    // &:hover {
    //     .firstRowRightBull {
    //         transform: scale(0.9) translate(0, 35px);
    //         @media (max-width: $md1+px) {
    //             transform: scale(0.9) translate(0, 30px);
    //         }
    //         @media (max-width: $md2+px) {
    //             transform: scale(0.9) translate(0, 24px);
    //         }
    //     }
    //     .firstRowRightEye {
    //         transform: scale(0.9) translate(0, 37px);
    //         @media (max-width: $md1+px) {
    //             transform: scale(0.9) translate(0, 32px);
    //         }
    //         @media (max-width: $md2+px) {
    //             transform: scale(0.9) translate(0, 26px);
    //         }
    //     }
    // }
}
.firstRowRightImg {
    position: relative;
}
.firstRowRightBull {
    position: absolute;
    left: 7px;
    top: -120px;
    transition: all 0.3s linear;
    width: 100%;
    height: 100%;
    background: url("../../../assets/img/main/1/bull.png") 0 0 / contain no-repeat;
    @media (max-width: $md1+px) {
        top: -97px;
    }
    @media (max-width: $md2+px) {
        top: -82px;
    }
    @media (max-width: $md3+px) {
        display: none;
    }
}
.firstRowRightEye {
    position: absolute;
    left: 7px;
    top: -120px;
    transition: all 0.3s linear;
    width: 100%;
    height: 100%;
    background: url("../../../assets/img/main/1/eye.png") 0 0 / contain no-repeat;
    transform: scale(1) translate(-7px, 5px);
    @media (max-width: $md1+px) {
        top: -97px;
    }
    @media (max-width: $md2+px) {
        top: -82px;
    }
    @media (max-width: $md3+px) {
        display: none;
    }
}
.firstRowRightEye {
}
