@import "../../styles/var";
.col1,
.col2,
.col3,
.col4,
.col5,
.col6,
.col7,
.col8 {
    transition: all 0.3s linear;
    position: relative;
    opacity: 0;
    top: 200px;
    @media (max-width: $md3+px) {
        top: 0;
        opacity: 1;
    }
}
