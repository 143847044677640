@import '../../../styles/var';

.startInvesting {
    position: relative;
    z-index: 5;
    margin-top: 130px;
    @media(max-width: $md4+px) {
        margin-top: 110px;
    }
    @media(max-width: $md6+px) {
        margin-top: 80px;
    }
    .title {
        text-align: center;
    }
    .text {
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin: 30px auto 50px auto;
        max-width: 722px;
    }
    .cards {
        width: 100%;
        // display: grid;
        // grid-template-columns: repeat(3, 1fr);
        // grid-template-rows: repeat(1, 1fr);
        // gap: 35px;
        display: flex;
        justify-content: center;
        gap: 150px;
        @media(max-width: $md1+px) {
            gap: 120px;
        }
        @media(max-width: $md4+px) {
            justify-content: space-between;
            gap: 20px;
        }
        @media(max-width: $md5+px) {
            gap: 15px;
        }
        @media(max-width: $md6+px) {
            gap: 10px;
        }
        .card {
            width: 241px;
            background: rgba(255, 253, 253, 0.6);
            box-shadow: 0px 5px 10px rgba(51, 48, 48, 0.25);
            backdrop-filter: blur(5px);
            border-radius: 16px;
            text-align: center;
            padding: 20px 5px 10px 5px;
            @media(max-width: 600px) {
                padding: 7px 0 5px 0;
                border-radius: 8.05378px;
            }
            &Title {
                font-weight: 700;
                font-size: 50px;
                line-height: 40px;
                color: #2196F3;
                @media(max-width: $md4+px) {
                    font-size: 42px;
                }
                @media(max-width: 600px) {
                    font-size: 18px;
                    line-height: 20px;
                }
            }
            &Text {
                @media(max-width: 600px) {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }
}