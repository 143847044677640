@import "../../styles/_var.scss";

.educationContainer {
    padding-top: 200px;
    background-image: url("../../assets/img/EducationPage/banner-bg.png");
    background-repeat: no-repeat;
    background-size: inherit;
    background-position: top 115px left;

    @media (max-width: $md4+px) {
        padding-top: 180px;
        background-image: none;
    }

    @media (max-width: $md5+px) {
        background-image: url("../../assets/img/EducationPage/banner-mobile-bg.png");
    }
}
.col1,
.col2,
.col3,
.col4,
.col5,
.col6,
.col7,
.col8 {
    transition: all 0.3s linear;
    position: relative;
    opacity: 0;
    top: 200px;
    @media (max-width: $md3+px) {
        top: 0;
        opacity: 1;
    }
}
