@import '../../../styles/var';

.stepsFor {
    position: relative;
    margin-top: 130px;
    @media(max-width: $md4+px) {
        margin-top: 110px;
    }
    @media(max-width: $md6+px) {
        margin-top: 80px;
    }
    .title {
        position: relative;
        z-index: 5;
        max-width: 800px;
    }
    .text {
        position: relative;
        z-index: 5;
        margin: 30px 0 70px 0;
        display: flex;
        flex-direction: column;
        gap: 15px;
        max-width: 688px;
        @media(max-width: $md1+px) {
            margin: 30px 0 60px 0;
        }
        @media(max-width: $md4+px) {
            max-width: 470px;
        }
        @media(max-width: 700px) {
            max-width: 100%;
        }
        @media(max-width: $md6+px) {
            margin: 30px 0 50px 0;
        }
    }
    .btn {
        position: relative;
        z-index: 5;
        @media(max-width: 700px) {
            text-align: center;
        }
    }
    img {
        position: absolute;
        z-index: 3;
        top: -10px;
        right: 0;
        // width: 613px;
        @media(max-width: $md1+px) {
            width: 550px;
            top: 0;
        }
        @media(max-width: $md3+px) {
            width: 450px;
            top: 80px;
            right: -20px;
        }
        @media(max-width: $md4+px) {
            width: 420px;
            right: -90px;
        }
        @media(max-width: 700px) {
            position: relative;
            display: flex;
            top: unset;
            right: unset;
            margin: 20px auto 0 auto;
            width: 450px;
        }
        @media(max-width: $md6+px) {
            width: 100%;
        }
    }
}