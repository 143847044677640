@import '../../../styles/var';

.faq {
    position: relative;
    margin: 130px 0;
    @media(max-width: $md4+px) {
        margin: 110px 0;
    }
    @media(max-width: $md6+px) {
        margin: 80px 0;
    }
    .title {
        text-align: center;
    }
    .spoilers {
        margin-top: 70px;
        max-width: 1080px;
        margin: 70px auto 0 auto;
        display: flex;
        flex-direction: column;
        gap: 30px;
        @media(max-width: $md1+px) {
            margin-top: 60px;
        }
        @media(max-width: $md4+px) {
            margin-top: 50px;
        }
        @media(max-width: $md6+px) {
            margin-top: 40px;
            gap: 20px;
        }
        .spoiler {
            cursor: pointer;
            background: linear-gradient(270.04deg, rgba(255, 255, 255, 0.6) 3.8%, rgba(255, 253, 253, 0) 103.58%);
            backdrop-filter: blur(5.25733px);
            border-radius: 16px;
            position: relative;
            padding: 5px 10px;
            overflow: hidden;
            box-shadow: 43px 29px 21px rgba(0, 0, 0, 0.01), 24px 16px 18px rgba(0, 0, 0, 0.05), 11px 7px 13px rgba(0, 0, 0, 0.09), 3px 2px 7px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
            z-index: 2;
            &::before {
                z-index: 1;
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: linear-gradient(270.04deg, rgba(68, 181, 233, 0.6) 3.8%, rgba(255, 253, 253, 0) 103.58%);
                opacity: 0;
                transition: all .3s;
            }
            &Active {
                &::before {
                    opacity: 1;
                }
                .spoilerHeaderBtn {
                    transform: rotate(90deg);
                }
            }
            &Header {
                z-index: 5;
                position: relative;
                display: grid;
                grid-template-columns: 40px 1fr 40px;
                gap: 40px;
                align-items: center;
                @media(max-width: $md6+px) {
                    gap: 20px;
                    grid-template-columns: 34px 1fr 34px;
                }
                &Num {
                    width: 40px;
                    height: 40px;
                    background: #2196F3;
                    font-weight: 800;
                    font-size: 20px;
                    line-height: 40px;
                    color: #FFFFFF;
                    border-radius: 100px;
                    text-align: center;
                    @media(max-width: $md6+px) {
                        width: 34px;
                        height: 34px;
                        font-size: 18px;
                        line-height: 34px;
                    }
                }
                &Text {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 150%;
                    color: #373737;
                    @media(max-width: $md4+px) {
                        font-size: 18px;
                    }
                    @media(max-width: $md6+px) {
                        font-size: 14px;
                    }
                }
                &Btn {
                    transition: all .3s;
                    width: 40px;
                    height: 40px;
                    background: rgba(255, 253, 253, 0.75);
                    box-shadow: 0px 4px 8px rgba(51, 48, 48, 0.25);
                    backdrop-filter: blur(4.20586px);
                    border-radius: 100px;
                    position: relative;
                    @media(max-width: $md6+px) {
                        width: 34px;
                        height: 34px;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        background-image: url('../../../assets/img/StartPage/WhyOpen/plus.svg');
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: 22px;
                        height: 22px;
                        left: calc(50% - 11px);
                        top: calc(50% - 11px);
                        @media(max-width: $md6+px) {
                            width: 16px;
                            height: 16px;
                            left: calc(50% - 8px);
                            top: calc(50% - 8px);
                        }
                    }
                }
            }
            &Content {
                padding: 20px 70px;
                position: relative;
                z-index: 5;
                display: flex;
                flex-direction: column;
                gap: 10px;
                font-weight: 400;
                font-size: 15px;
                line-height: 22px;
                color: #373737;
                @media(max-width: $md4+px) {
                    padding: 20px 40px;
                }
                @media(max-width: $md6+px) {
                    padding: 20px 10px;
                    font-size: 12px;
                    line-height: 140%;
                    gap: 6px;
                }
                ul {
                    padding-left: 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    @media(max-width: $md6+px) {
                        gap: 6px;
                    }
                    li {
                        list-style-type: disc;
                        list-style-position: outside;
                    }
                }
            }
        }
    }
}