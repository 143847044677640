@import "../../../styles/_var.scss";

div {
    &.container {
        margin-top: 130px;

        @media (max-width: $md4+px) {
            margin-top: 110px;
        }

        @media (max-width: $md2+px) {
            margin-top: 110px;
        }

        @media (max-width: $md6+px) {
            margin-top: 80px;
        }
    }
}

.title {
    margin-bottom: 30px;
}

.textMb70 {
    margin-bottom: 70px;
}

.itemWrapLeft {
    padding-right: 50px;

    @media (max-width: $md2+px) {
        padding-right: 30px;
    }

    @media (max-width: $md4+px) {
        padding-right: 0px;
    }
}

.itemWrapRight {
    padding-left: 50px;

    @media (max-width: $md2+px) {
        padding-left: 30px;
    }

    @media (max-width: $md4+px) {
        padding-left: 0px;
    }
}

.text {
    margin-bottom: 15px;
}

.btnWrap {
    margin-top: 70px;

    @media (max-width: $md4+px) {
        margin-bottom: 100px;
    }

    @media (max-width: $md5+px) {
        display: none;
    }
}

.btnWrapMobile {
    display: none;
    margin-top: 60px;

    @media (max-width: $md5+px) {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
    }
}

.imgWrap {
    margin-top: 40px;
    display: flex;
    justify-content: center;

    img {
        max-width: 100%;
        height: auto;
    }
}