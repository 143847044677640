@import "../../../styles/_var.scss";
.overview {
    margin-bottom: 130px;
    @media (max-width: $md4+px) {
        margin-bottom: 110px;
    }
    @media (max-width: $md2+px) {
        margin-bottom: 110px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 80px;
    }
}
.overviewBody {
    position: relative;
    .overviewLeftImg {
        display: inline-block;
        left: -900px;
        right: 0;
        margin: auto;
        top: 180px;
        position: absolute;
        width: 136px;
        height: 185px;
        background: url("../../../assets/img/fees/3/1.png") 0 0 / contain no-repeat;
        transition: all 0.3s linear;
        opacity: 0;
        top: 300px;
        @media (max-width: $md3+px) {
            opacity: 1;
            top: 180px;
        }
        @media (max-width: $md4+px) {
            display: none;
        }
    }
    .overviewRightImg {
        display: inline-block;
        left: 900px;
        right: 0;
        margin: auto;
        transition: all 0.3s linear;
        top: 140px;
        position: absolute;
        width: 293px;
        height: 336px;
        background: url("../../../assets/img/fees/3/2.png") 0 0 / contain no-repeat;
        @media (max-width: $md3+px) {
            left: 750px;
            top: -40px;
        }
        @media (max-width: $md4+px) {
            display: none;
        }
    }
}
.overviewTitle {
    max-width: 500px;
    text-align: center;
    margin: 0 auto 30px auto;
}

.overviewSubtitle {
    font-weight: 500;
    max-width: 500px;
    margin: 0 auto 60px auto;
    text-align: center;
    @media (max-width: $md5+px) {
        margin-bottom: 30px;
    }
}
.overviewTableWrap {
    width: 696px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    @media (max-width: $md5+px) {
        width: 482px;
    }
}
.overviewTableWrapRow {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #aaaaaa;
    padding: 13px 0;
}
.overviewTableWrapRowColumn {
    flex: 0 1 50%;
    font-size: 16px;
    line-height: 1.1;
    color: #373737;
    &:nth-child(2) {
        p {
            text-align: right;
        }
    }
}
.overviewTableWrapScroll {
    overflow: auto;
    padding-bottom: 20px;
    &::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 10px;
        border: 1px solid #56bfef;
    }
    &::-webkit-scrollbar {
        height: 6px;
        background-color: #fff;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 25px;
        background-color: #56bfef;
    }
}
.overviewImg {
    display: none;
    text-align: center;
    @media (max-width: $md4+px) {
        display: block;
        margin: 30px auto 0 auto;
    }
}

.overviewText {
    margin-top: 50px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    max-width: 1150px;
    color: #373737;
    @media (max-width: $md5+px) {
        margin-top: 30px;
    }
    @media (max-width: $md6+px) {
        font-size: 12px;
        line-height: 18px;
    }
}
