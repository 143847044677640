@import '../../../styles/var';

.requirements {
    position: relative;
    margin-top: 195px;
    @media(max-width: $md4+px) {
        margin-top: 150px;
    }
    @media(max-width: $md6+px) {
        margin-top: 80px;
    }
    .title {
        position: relative;
        z-index: 10;
    }
    .content {
        position: relative;
        z-index: 10;
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        @media(max-width: $md3+px) {
            flex-direction: column;
            gap: 15px;
        }
        .col {
            width: 688px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            @media(max-width: $md1+px) {
                width: 610px;
            }
            @media(max-width: $md2+px) {
                width: 560px;
            }
            @media(max-width: $md3+px) {
                width: 100%;
            }
            ul {
                padding-left: 30px;
                display: flex;
                flex-direction: column;
                gap: 15px;
                @media(max-width: $md1+px) {
                    gap: 10px;
                }
                @media(max-width: $md6+px) {
                    padding-left: 20px;
                }
                li {
                    list-style-position: outside;
                    position: relative;
                    &::before {
                        content: '';
                        position: absolute;
                        width: 14px;
                        height: 14px;
                        background: #2196F3;
                        left: -25px;
                        top: 7px;
                        border-radius: 100px;
                        @media(max-width: $md6+px) {
                            width: 8px;
                            height: 8px;
                            top: 6px;
                            left: -16px;
                        }
                    }
                }
            }
        }
    }
    .rect_01 {
        position: absolute;
        z-index: 3;
        width: 285px;
        height: 285px;
        background: rgba(255, 253, 253, 0.6);
        box-shadow: 0px 5px 10px rgba(51, 48, 48, 0.25);
        backdrop-filter: blur(5.25733px);
        border-radius: 16.8235px;
        transform: rotate(45deg);
        top: -100px;
        left: -200px;
        @media(max-width: $md1+px) {
            width: 220px;
            height: 220px;
            top: -60px;
            left: -120px;
        }
        @media(max-width: $md4+px) {
            box-shadow: 0px 3.0344px 6.0688px rgba(51, 48, 48, 0.25);
            width: 173px;
            height: 173px;
            left: 20px;
            top: 0px;
            border-radius: 10.2098px;
        }
    }
    .rect_02 {
        position: absolute;
        z-index: 3;
        width: 200.85px;
        height: 200.85px;
        background: rgba(255, 253, 253, 0.6);
        box-shadow: 0px 5px 10px rgba(51, 48, 48, 0.25);
        backdrop-filter: blur(5.25733px);
        border-radius: 16.8235px;
        transform: rotate(45deg);
        left: -10px;
        top: 180px;
        @media(max-width: $md1+px) {
            width: 150px;
            height: 150px;
            top: 160px;
            left: 20px;
        }
        @media(max-width: $md4+px) {
            box-shadow: 0px 3.0344px 6.0688px rgba(51, 48, 48, 0.25);
            width: 120px;
            height: 120px;
            top: 190px;
            left: 120px;
            border-radius: 10.2098px;
        }
    }
    .rect_03 {
        position: absolute;
        z-index: 3;
        width: 395px;
        height: 395px;
        background: rgba(255, 253, 253, 0.6);
        box-shadow: 0px 5px 10px rgba(51, 48, 48, 0.25);
        backdrop-filter: blur(5.25733px);
        border-radius: 16.8235px;
        transform: rotate(45deg);
        top: -120px;
        right: -160px;
        @media(max-width: $md1+px) {
            width: 300px;
            height: 300px;
            right: -80px;
            top: -80px;
        }
        @media(max-width: $md2+px) {
            width: 260px;
            height: 260px;
        }
        @media(max-width: $md4+px) {
            display: none;
        }
    }
    .rect_04 {
        position: absolute;
        z-index: 3;
        width: 285px;
        height: 285px;
        background: rgba(255, 253, 253, 0.6);
        box-shadow: 0px 5px 10px rgba(51, 48, 48, 0.25);
        backdrop-filter: blur(5.25733px);
        border-radius: 16.8235px;
        transform: rotate(45deg);
        right: 310px;
        top: 70px;
        @media(max-width: $md1+px) {
            width: 200px;
            height: 200px;
            right: 280px;
            top: 80px;
        }
        @media(max-width: $md2+px) {
            right: 250px;
        }
        @media(max-width: $md4+px) {
            display: none;
        }
    }
}