@import "../../../styles/_var.scss";
.marketsRowLeft {
    flex: 0 1 722px;
    @media (max-width: $md2+px) {
        flex: 0 1 600px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 500px;
    }
    @media (max-width: $md4+px) {
        flex: 0 1 100%;
        margin-bottom: 50px !important;
    }
}
.marketsRowLeftTitle {
    max-width: 500px;

    margin-bottom: 30px;
    @media (max-width: $md4+px) {
        max-width: 100%;
    }
}
.marketsRowLeftSubtitle {
}
.mb15 {
    margin-bottom: 15px;
}
.marketsRowLeftList {
    margin-top: 40px;
    @media (max-width: $md6+px) {
        margin-top: 30px;
    }
}
.marketsRowLeftListItem {
}

.markets {
    margin-bottom: 130px;
    @media (max-width: $md4+px) {
        margin-bottom: 110px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 80px;
    }
}
.marketsBody {
}
.marketsRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md4+px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.marketsRowLeft {
    @media (max-width: $md4+px) {
        flex: 0 1 100%;
        margin-bottom: 0px;
    }
}
.marketsRowLeftTitle {
}
.marketsRowLeftSubtitle {
}
.marketsRowLeftList {
}
.marketsRowLeftListItem {
    padding-left: 60px;
    position: relative;
    margin-bottom: 40px;
    @media (max-width: $md6+px) {
        margin-bottom: 20px;
        padding-left: 40px;
    }
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: -4px;
        position: absolute;
        width: 40px;
        height: 40px;
        @media (max-width: $md6+px) {
            top: -4px;
            width: 30px;
            height: 30px;
        }
    }
    &:nth-child(1) {
        &:after {
            background: url("../../../assets/img/main/8/1.svg") 0 0 / contain no-repeat;
        }
    }
    &:nth-child(2) {
        &:after {
            background: url("../../../assets/img/main/8/2.svg") 0 0 / contain no-repeat;
        }
    }
    &:nth-child(3) {
        &:after {
            background: url("../../../assets/img/main/8/3.svg") 0 0 / contain no-repeat;
        }
    }
    &:nth-child(4) {
        &:after {
            background: url("../../../assets/img/main/8/4.svg") 0 0 / contain no-repeat;
        }
        margin-bottom: 0;
    }
}
.marketsRowRight {
    position: relative;
    top: 50px;
    width: 516px;
    @media (max-width: $md4+px) {
        top: 0;
    }
    @media (max-width: $md3+px) {
        width: 400px;
    }
    img {
        width: 100%;
    }
    &1,
    &2,
    &3,
    &4 {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 3;
        height: 100%;
    }
    &1 {
        background: url("../../../assets/img/main/8/1.png") 0 0 / contain no-repeat;
    }
    &2 {
        background: url("../../../assets/img/main/8/2.png") 0 0 / contain no-repeat;
    }
    &3 {
        background: url("../../../assets/img/main/8/3.png") 0 0 / contain no-repeat;
    }
    &4 {
        background: url("../../../assets/img/main/8/4.png") 0 0 / contain no-repeat;
    }
}
