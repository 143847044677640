@import "../../../styles/_var.scss";
.row {
    margin-bottom: 130px;
    @media (max-width: $md4+px) {
        margin-bottom: 110px;
    }

    @media (max-width: $md6+px) {
        margin-bottom: 80px;
    }
}
.rowContentWrapper {
    @media (max-width: $md3+px) {
        overflow-x: auto;
        overflow-y: hidden;

        padding: 15px 15px 15px 15px;
        &::-webkit-scrollbar-track {
            background-color: #fff;
            border-radius: 10px;
            border: 1px solid #56bfef;
        }

        &::-webkit-scrollbar {
            height: 6px;
            background-color: #fff;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 25px;
            background-color: #56bfef;
        }
    }
}
.rowContent {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        width: 1200px;
    }
}
.rowContentColumn {
}
.rowContentColumnIcon {
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img {
        height: 60% !important;
        width: 60% !important;
    }
    @media (max-width: $md6+px) {
        height: 60px;
        width: 60px;
        img {
            height: 50% !important;
            width: 50% !important;
        }
    }
    margin: 0 auto 30px auto;
    @media (max-width: $md6+px) {
        margin: 0 auto 20px auto;
    }
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        transform: rotate(45deg);
        background: rgba(255, 253, 253, 0.6);
        /* shadows */

        box-shadow: 0px 5px 10px rgba(51, 48, 48, 0.25);
        backdrop-filter: blur(5.25733px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 16.8235px;
        @media (max-width: $md3+px) {
            box-shadow: unset;
            border: 1px solid rgba(51, 48, 48, 0.25);
        }
    }
}
.rowContentColumnText {
    font-weight: 600 !important;
    text-align: center;
    color: #373737 !important;
}
