@import "../../../styles/_var.scss";
.online {
    margin-bottom: 130px;
    @media (max-width: $md4+px) {
        margin-bottom: 110px;
    }
    @media (max-width: $md2+px) {
        margin-bottom: 110px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 80px;
    }
}
.onlineBody {
}
.onlineTitle {
    max-width: 948px;
    margin: 0 auto 40px auto;
    text-align: center;
    @media (max-width: $md5+px) {
        margin: 0 auto 30px auto;
    }
    @media (max-width: $md6+px) {
        text-align: left !important;
    }
}
.onlineText {
    max-width: 792px;
    text-align: center;
    margin: 0 auto 60px auto;
    @media (max-width: $md6+px) {
        text-align: left !important;
    }
}
.onlineRowWrapper {
    @media (max-width: $md2+px) {
        overflow: auto;
        padding-bottom: 20px;
    }
    &::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 10px;
        border: 1px solid #56bfef;
    }

    &::-webkit-scrollbar {
        height: 6px;
        background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 25px;
        background-color: #56bfef;
    }
}
.onlineRow {
    display: flex;
    justify-content: space-between;
    max-width: 1414px;
    margin: 0 auto;
    @media (max-width: $md2+px) {
        width: 1200px;
    }
    @media (max-width: $md4+px) {
        width: 942px;
    }
}
.onlineRowColumn {
    width: 378px;
    height: 307px;
    border: 5px solid #56bfef;
    box-shadow: 0px 0px 50px 1px #56bfef, inset 0px 0px 50px 1px #56bfef;
    @media (max-width: $md2+px) {
        box-shadow: inset 0px 0px 50px 1px #56bfef;
    }
    @media (max-width: $md4+px) {
        padding-top: 20px !important;
    }
    @media (max-width: $md6+px) {
        padding-top: 50px !important;
    }
    border-radius: 40px;
    padding: 20px 25px 0 25px;
    position: relative;
    cursor: pointer;
    position: relative;
    @media (max-width: $md4+px) {
        width: 290px;
        height: 235px;
        padding: 0px 15px 0 15px;
    }
    &:before {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        pointer-events: none;
        opacity: 0;
        transition: all 0.3s linear;
        border-radius: 35px;
        backdrop-filter: blur(5px);
    }

    &:after {
        transition: all 0.3s linear;
        @media (max-width: $md4+px) {
            display: none !important;
        }
    }
    &:hover {
        .onlineRowColumnItemText {
            opacity: 1;
        }
        &:before {
            opacity: 1;
        }
        &::after {
            opacity: 0.2;
        }
    }
}
.onlineRowColumn1 {
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 10px;
        position: absolute;
        pointer-events: none;
        width: 217px;
        height: 215px;
        background: url("../../../assets/img/main/3/1.png") 0 0 / contain no-repeat;
        @media (max-width: $md4+px) {
            width: 137px;
            height: 135px;
        }
    }
}
.onlineRowColumn2 {
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 20px;
        position: absolute;
        pointer-events: none;
        width: 253px;
        height: 181px;
        background: url("../../../assets/img/main/3/2.png") 0 0 / contain no-repeat;
        @media (max-width: $md4+px) {
            width: 193px;
            height: 121px;
        }
    }
}
.onlineRowColumn3 {
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 10px;
        pointer-events: none;
        position: absolute;
        width: 165px;
        height: 207px;
        background: url("../../../assets/img/main/3/3.png") 0 0 / contain no-repeat;
        @media (max-width: $md4+px) {
            width: 105px;
            height: 147px;
            bottom: 0;
        }
    }
}
.onlineRowColumnItem {
    position: relative;
    z-index: 3;
}
.onlineRowColumnItemTitle {
    margin: 0 auto 40px auto;
    font-weight: 600;
    text-align: center;
    color: #2196f3;
    @media (max-width: $md4+px) {
        margin: 0 auto 10px auto;
        // height: 100%;
    }
}
.onlineRowColumnItemText {
    font-weight: 500;
    text-align: center;
    transition: all 0.3s linear;
    opacity: 0;
    color: #3a3c3c;
    @media (max-width: $md4+px) {
        opacity: 1;
    }
}
