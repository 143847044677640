@import "../../../styles/var";

.footer {
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    z-index: 5;
}
.footerBody {
    background: #44b5e9;
    border-radius: 60px 60px 0px 0px;
    padding: 30px 0;
    @media (max-width: 1672px) {
        padding: 42px 0 22px 0;
    }
    @media (max-width: 1030px) {
        padding: 40px 0 30px 0;
        border-radius: 40px 40px 0 0;
    }
}
.footerRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}
.footerRowLeft {
    flex: 0 1 895px;
    @media (max-width: $md1+px) {
        flex: 0 1 700px;
    }
    @media (max-width: $md3+px) {
        order: 3;
        flex: 0 1 100%;
        margin-top: 20px;
    }
}
.footerRowLeftText {
    font-weight: 300;
    font-size: 12px;
    line-height: 1.2;
    color: #fff;
    margin-bottom: 15px;
    @media (max-width: $md6+px) {
        font-size: 10px;
        margin-bottom: 10px;
    }
    &:last-child {
        margin-bottom: 0;
    }
}
.footerRowCenter {
    display: flex;
    flex-direction: column;
    gap: 15px;
    @media (max-width: $md3+px) {
        margin-right: 100px;
    }
}
.footerRowCenterItem,
.footerRowRightText,
.footerRowRightContact,
.footerRowRightPhone,
.footerRowRightEmail {
    display: block;
    font-weight: 300;
    font-size: 15px;
    line-height: 1.2;
    color: #fff;
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}
.footerRowCenterItem {
    // margin-bottom: 20px;
    // @media (max-width: $md6+px) {
    //     margin-bottom: 10px;
    // }
    &:last-child {
        margin-bottom: 0;
    }
}
.footerRowRight {
    flex: 0 1 326px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    @media (max-width: $md1+px) {
        flex: 0 1 250px;
    }
    @media (max-width: 550px) {
        flex: 0 1 100%;
        margin-top: 20px;
    }
    a {
        // margin-top: 10px;
    }
}
.footerRowRightText {
    margin-bottom: 20px;
    @media (max-width: $md5+px) {
        margin-bottom: 10px;
    }
}
.footerRowRightContact {
}
.footerRowRightPhone {
}
.footerRowRightEmail {
}

a:hover {
    text-decoration: underline!important;
}

.rights {
    color: #fff;
    margin-top: 20px;
}