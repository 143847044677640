@import '../../../styles/var';

.accessTo {
    position: relative;
    margin-top: 130px;
    @media(max-width: $md4+px) {
        margin-top: 110px;
    }
    @media(max-width: $md6+px) {
        margin-top: 80px;
    }
    .content {
        max-width: 754px;
        position: relative;
        z-index: 5;
        @media(max-width: $md3+px) {
            max-width: 680px;
        }
        .text {
            margin: 30px 0 80px 0;
            display: flex;
            flex-direction: column;
            gap: 15px;
            @media(max-width: $md4+px) {
                margin: 30px 0 20px 0;
            }
        }
    }
    .gif {
        position: absolute;
        z-index: 3;
        top: 0;
        right: 100px;
        width: 329px;
        @media(max-width: $md1+px) {
            width: 300px;
            right: 0;
        }
        @media(max-width: $md3+px) {
            width: 280px;
            top: 100px;
        }
        @media(max-width: $md4+px) {
            position: relative;
            display: flex;
            top: unset;
            right: unset;
            margin: 0 auto;
            width: 329px;
        }
        @media(max-width: $md6+px) {
            width: 219px;
        }
    }
    .tabs {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 750px;
        grid-template-rows: repeat(1, 1fr);
        gap: 50px;
        @media(max-width: $md1+px) {
            grid-template-columns: 1fr 600px;
        }
        @media(max-width: $md2+px) {
            grid-template-columns: 1fr 500px;
        }
        @media(max-width: $md3+px) {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(2, 0fr);
            gap: 30px;
        }
        @media(max-width: $md4+px) {
            margin-top: 15px;
        }
        @media(max-width: $md6+px) {
            margin-top: 30px;
        }
        .btns {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(3, 1fr);
            gap: 14px 50px;
            @media(max-width: $md1+px) {
                gap: 14px 30px;
            }
            @media(max-width: $md4+px) {
                gap: 10px 15px;
            }
            @media(max-width: $md6+px) {
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(4, 1fr);
                gap: 10px;
            }
            .btn {
                cursor: pointer;
                background: #2196F3;
                border-radius: 16px;
                transition: all .3s;
                font-weight: 400;
                font-size: 18px;
                line-height: 27px;
                color: #FFFFFF;
                text-align: center;
                padding: 7px 0;
                @media(max-width: $md4+px) {
                    font-size: 15px;
                    line-height: normal;
                    border-radius: 13px;
                }
                @media(max-width: 600px) {
                    font-size: 12px;
                    line-height: 18px;
                }
                @media(max-width: $md6+px) {
                    border-radius: 10px;
                }
                &Active {
                    background: #334C65;
                }
            }
        }
        ::-webkit-scrollbar {
            width: 10px;
        }
        ::-webkit-scrollbar-track {
            background: #fff;
        }
        ::-webkit-scrollbar-thumb {
            background: #2196F3;
        }
        ::-webkit-scrollbar-thumb:hover {
            background: #155e99;
        }
        .tabsContentWr {
            background: #FFFFFF;
            border: 1px dashed #A6A6A6;
            padding: 10px;
            overflow: auto;
            overflow-x: hidden;
            display: flex;
            height: 150px;
            .tabsContent {
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #373737;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 0 100px;
                @media(max-width: $md1+px) {
                    gap: 0 60px;
                }
                @media(max-width: $md3+px) {
                    grid-template-columns: repeat(3, 1fr);
                }
                @media(max-width: $md4+px) {
                    gap: 0 30px;
                    font-size: 13px;
                    line-height: 19px;
                }
                @media(max-width: $md6+px) {
                    font-size: 12px;
                    line-height: normal;
                }
                @media(max-width: $md5+px) {
                    grid-template-columns: repeat(2, 1fr);
                }
                @media(max-width: $md6+px) {
                    grid-template-columns: repeat(1, 1fr);
                }
            }
        }
    }
}