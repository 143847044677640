@import "../../../styles/_var.scss";

div {
    &.container {
        margin-top: 130px;

        @media (max-width: $md4+px) {
            margin-top: 110px;
        }

        @media (max-width: $md2+px) {
            margin-top: 110px;
        }

        @media (max-width: $md6+px) {
            margin-top: 80px;
        }
    }
}

.title {
    margin-bottom: 30px;
}

.itemWrapLeft {
    padding-right: 50px;

    @media (max-width: $md2+px) {
        padding-right: 30px;
    }

    @media (max-width: $md4+px) {
        padding-right: 0px;
    }
}

.itemWrapRight {
    padding-left: 50px;

    @media (max-width: $md2+px) {
        padding-left: 30px;
    }

    @media (max-width: $md4+px) {
        padding-left: 0px;
    }
}

.text {
    margin-bottom: 15px;
}

.btnWrap {
    margin-top: 70px;

    @media (max-width: $md4+px) {
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
    }
}

.imgWrap {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    img {
        max-width: 100%;
        height: auto;

        @media (max-width: $md5+px) {
            max-width: 240px;
        }
    }
}