@import "../../../styles/_var.scss";
.brokerageRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}
.brokerageRowRight {
    flex: 0 1 722px;
    @media (max-width: $md1+px) {
        flex: 0 1 600px;
    }
    @media (max-width: $md2+px) {
        flex: 0 1 550px;
    }

    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-bottom: 0px !important;
    }
}
.brokerageRowRightTitle {
    margin-bottom: 30px;
    @media (max-width: $md3+px) {
        max-width: 100%;
    }
}
.brokerageRowRightSubtitle {
}
.mb15 {
    margin-bottom: 15px;
}

.brokerageRowLeft {
    flex: 0 1 722px;
    @media (max-width: $md1+px) {
        flex: 0 1 600px;
    }
    @media (max-width: $md2+px) {
        flex: 0 1 550px;
    }

    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin-bottom: 15px !important;
    }
}
.brokerageRowLeftTitle {
    margin-bottom: 30px;
    max-width: 653px;
    @media (max-width: $md1+px) {
        max-width: 550px;
    }
    @media (max-width: $md3+px) {
        max-width: 100%;
    }
}
.brokerageRowLeftSubtitle {
    &.fw600 {
        font-weight: 600 !important;
    }
}
.mb15 {
    margin-bottom: 15px;
}
.brokerage {
    padding: 230px 0 0 0;

    margin-bottom: 130px;
    @media (max-width: $md4+px) {
        margin-bottom: 110px;
        padding: 160px 0 0 0;
    }
    @media (max-width: $md5+px) {
        position: relative;
        &:after {
            content: "";
            display: inline-block;
            left: 0;
            top: 100px;
            position: absolute;
            width: 370px;
            height: 352px;
            background: url("../../../assets/img/fees/1/mob.png") 0 0 / contain no-repeat;
        }
    }
    @media (max-width: $md6+px) {
        margin-bottom: 80px;
        padding: 136px 0 0 0;
    }
}
.brokerageBody {
    position: relative;
    z-index: 2;
}
.brokerageRow {
}
.brokerageRowLeft,
.brokerageRowRight {
    display: flex;
    flex-direction: column;
}
.brokerageRowLeftWrapper,
.brokerageRowRightWrapper {
    margin-bottom: 70px;
    @media (max-width: $md3+px) {
        margin-bottom: 0px !important;
    }

    flex: 0 1 100%;
}
.brokerageRowLeftTitle {
}

.brokerageRowLeftSubtitle {
}

.brokerageRowLeftBot {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        width: 100%;
        justify-content: center;
        display: none;
    }
    &.mob {
        display: none;
        @media (max-width: $md3+px) {
            display: flex;
            margin-top: 30px;
        }
    }
    @media (max-width: 570px) {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
}
.brokerageRowLeftBotEmail {
    width: 379px;
    height: 50px;
    @media (max-width: $md2+px) {
        width: 300px;
    }
    @media (max-width: 570px) {
        margin-bottom: 20px;
        width: 100%;
    }
    background: #334c65;
    position: relative;
    input {
        font-weight: 500;
        line-height: 50px;
        color: #ffffff;
        background-color: transparent;
        padding: 0 13px;
        &::placeholder {
            color: #ffffff;
        }
    }
}
.brokerageRowLeftList {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.brokerageRowLeftItem {
    padding-left: 20px;
    position: relative;
    &:after {
        content: "";
        display: inline-block;
        left: 5px;
        top: 12px;
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #373737;
        @media (max-width: $md4+px) {
            top: 10px;
        }
        @media (max-width: $md6+px) {
            top: 7px;
        }
    }
}
.brokerageRowLeftSubs,
.brokerageRowRightBotOpen {
    position: relative;
    top: 7px;
}

.brokerageRowRightBotOpen {
    @media (max-width: 570px) {
        margin: 0 auto;
    }
}
.fw500 {
    font-weight: 500;
}
