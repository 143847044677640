@import "../../../styles/_var.scss";
.invest {
    margin-bottom: 130px;
    @media (max-width: $md4+px) {
        margin-bottom: 110px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 80px;
    }
}
.investBody {
    background-color: #ececec;
    padding: 40px 0;
    position: relative;
    overflow: hidden;
    @media (max-width: $md4+px) {
        padding: 30px 0;
    }
}
.cont {
    position: relative;
    &:after {
        content: "";
        display: inline-block;
        right: 0;
        top: 20px;
        position: absolute;
        width: 630px;
        height: 496px;
        background: url("../../../assets/img/main/2/1.png") 0 0 / contain no-repeat;
        @media (max-width: $md1+px) {
            width: 530px;
            height: 396px;
            right: -130px;
            top: 0;
            bottom: 0;
            margin: auto;
        }
        @media (max-width: $md2+px) {
            right: -50px;
        }
        @media (max-width: $md3+px) {
            right: -250px;
        }
        @media (max-width: $md4+px) {
            display: none;
        }
    }
}
.investWrapper {
    max-width: 948px;
    position: relative;
    z-index: 2;
    @media (max-width: $md2+px) {
        max-width: 750px;
    }
}
.investTitle {
    margin-bottom: 30px;
    @media (max-width: $md4+px) {
        margin-bottom: 15px;
    }
}
.investSubtitle {
    margin-bottom: 25px;
    color: #373737;
    @media (max-width: $md4+px) {
        margin-bottom: 15px;
    }
}
.investList {
}
.investListItem {
    margin-bottom: 15px;
    position: relative;
    padding-left: 30px;
    color: #373737;
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 8px;
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #2196f3;
        @media (max-width: $md4+px) {
            top: 6px;
        }
        @media (max-width: $md6+px) {
            top: 3px;
        }
    }
}
.investText {
    margin-top: 15px;
    font-weight: 600;
    color: #373737;
    margin-bottom: 70px;
    @media (max-width: $md4+px) {
        margin-bottom: 30px;
    }
}
.investBtn {
}
