@import '../../../styles/var';

.investIn {
    position: relative;
    z-index: 10;
    margin-top: 140px;
    @media(max-width: $md4+px) {
        margin-top: 110px;
    }
    @media(max-width: $md6+px) {
        margin-top: 80px;
    }
    .title {
        position: relative;
        z-index: 11;
        max-width: 754px;
    }
    .content {
        position: relative;
        z-index: 11;
        margin: 30px 0 70px 0;
        display: flex;
        justify-content: space-between;
        @media(max-width: $md3+px) {
            justify-content: unset;
            flex-direction: column;
            gap: 15px;
            margin: 30px 0 60px 0;
        }
        .col {
            width: 719px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            @media(max-width: $md1+px) {
                width: 600px;
            }
            @media(max-width: $md2+px) {
                width: 550px;
            }
            @media(max-width: $md3+px) {
                width: 100%;
            }
            b {
                font-weight: 500;
            }
        }
    }
    .btn {
        position: relative;
        z-index: 11;
        @media(max-width: $md6+px) {
            text-align: center;
        }
    }
    .rect_01, .rect_02, .rect_03, .rect_04 {
        position: absolute;
        z-index: 10;
        background: rgba(255, 253, 253, 0.6);
        box-shadow: 0px 5px 10px rgba(51, 48, 48, 0.25);
        backdrop-filter: blur(5.25733px);
        border-radius: 16.8235px;
        transform: rotate(45deg);
        @media(max-width: $md4+px) {
            background: rgba(255, 253, 253, 0.6);
            box-shadow: 0px 2.21486px 4.42971px rgba(51, 48, 48, 0.25);
            backdrop-filter: blur(2.32884px);
            border-radius: 7.4523px;
        }
    }
    .rect_01 {
        width: 342px;
        height: 342px;
        top: -130px;
        right: -170px;
        @media(max-width: $md1+px) {
            width: 300px;
            height: 300px;
        }
        @media(max-width: $md4+px) {
            width: 196px;
            height: 196px;
            right: unset;
            top: 90px;
            left: -90px;
        }
    }
    .rect_02 {
        width: 252px;
        height: 252px;
        top: -190px;
        right: 240px;
        @media(max-width: $md1+px) {
            width: 200px;
            height: 200px;
            right: 170px;
        }
        @media(max-width: $md4+px) {
            width: 138px;
            height: 138px;
            top: 0;
            right: unset;
            left: 90px;
        }
    }
    .rect_03 {
        width: 192px;
        height: 192px;
        top: 130px;
        right: 180px;
        @media(max-width: $md1+px) {
            width: 130px;
            height: 130px;
            right: 150px;
            top: 70px;
        }
        @media(max-width: $md4+px) {
            width: 99px;
            height: 99px;
            top: 100px;
            right: unset;
            left: 220px;
        }
    }
    .rect_04 {
        width: 252px;
        height: 252px;
        right: 420px;
        top: 30px;
        @media(max-width: $md1+px) {
            width: 200px;
            height: 200px;
            right: 330px;
            top: -20px;
        }
        @media(max-width: $md4+px) {
            display: none;
        }
    }
}